// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'About Us',
    icon: <Iconify icon={'heroicons-solid:user-group'} {...ICON_SIZE} />,
    path: '/about-us',
  },
  // {
  //   title: 'Our Products',
  //   icon: <Iconify icon={'icon-park-solid:more-four'} {...ICON_SIZE} />,
  //   path: '/our-products',
  // },
  {
    title: 'Contact Us',
    icon: <Iconify icon={'ic:baseline-perm-contact-calendar'} {...ICON_SIZE} />,
    path: '/contact-us',
  },
];

export default menuConfig;
