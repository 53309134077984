import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link as MaterialLink, Divider, Typography, Stack } from '@mui/material';
// data
import { useSelector, useDispatch } from '../../redux/store';
import { getBranches } from '../../redux/slices/portal-branch';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
// layouts
import Container from './MainContainer';
import PRIMARY from '../../theme/palette';

const SOCIALS = [
  { name: 'Facebook', href: 'https://www.facebook.com/lcorelendinginvestorinc' },
  { name: 'Instagram', href: 'https://instagram.com/lcorelendinginvestorinc?igshid=YmMyMTA2M2Y=' },
];

const LINKS = [
  {
    headline: 'OUR BRANCHES',
    children: [],
  },
];

const RootStyle = styled('div')(() => ({
  position: 'relative',
  backgroundColor: 'common.white',
  minHeight: 520,
  paddingBottom: 25,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const dispatch = useDispatch();

  const { isInitializing, branches } = useSelector((state) => state.portalBranches);

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  if (isInitializing) {
    return <LoadingScreen />;
  }

  LINKS[0].children = branches?.map((branch) => ({ name: branch?.address?.addressLine, href: '#' }));

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            <Stack justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mt: 5, mb: { xs: 2, md: 0 } }}>
              <Typography component="p" variant="overline" sx={{ color: 'common.black', mb: 2 }}>
                FOLLOW US
              </Typography>
              {SOCIALS.map((link) => (
                <MaterialLink key={link?.name} href={link?.href} sx={{ textDecoration: 'none', my: 0.5 }}>
                  {link?.name}
                </MaterialLink>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={9}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" sx={{ color: 'common.black', mt: 3 }}>
                    {list?.headline}
                  </Typography>
                  <Grid container xs={12} md={12}>
                    {list?.children?.map((link) => (
                      <>
                        <Stack key={link?.name} gap={2} sx={{ flexDirection: 'row', my: 1 }}>
                          {list?.headline === 'OUR BRANCHES' && (
                            <Stack sx={{ flexDirection: 'column' }}>
                              <Iconify icon="carbon:location-filled" sx={{ color: 'primary.main', mt: 0.5 }} />
                            </Stack>
                          )}
                          <Grid item xs={12} md={6} textAlign="left">
                            <Link
                              to={link?.href}
                              variant="body2"
                              style={{
                                textDecoration: 'none',
                                textAlign: { xs: 'center', lg: 'left' },
                                color: list.headline === 'OUR BRANCHES' ? 'black' : PRIMARY.light.primary.main,
                                width: '100%',
                              }}
                            >
                              {link?.name}
                            </Link>
                          </Grid>
                        </Stack>
                      </>
                    ))}
                  </Grid>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            color: 'common.black',
            mt: 10,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
          display={{ xl: 'inline' }}
        >
          © 2023 DMAY Lending Management Corporation. All rights reserved.
        </Typography>
      </Container>
    </RootStyle>
  );
}
