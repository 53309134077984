// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_main_dashboard'),
  generalConfiguration: getIcon('ic_general_config'),
  generalBranchConfiguration: getIcon('ic_branch_config'),
  generalSMSConfiguration: getIcon('ic_sms'),
  branch: getIcon('ic_branch'),
  role: getIcon('ic_role'),
  user: getIcon('ic_user'),
  agent: getIcon('ic_agent'),
  loan: getIcon('ic_loan'),
  borrower: getIcon('ic_borrower'),
  collection: getIcon('ic_collection'),
  atmDailyCollection: getIcon('ic_atm_daily_collection'),
  loanReleases: getIcon('ic_loan_releases'),
  collectionReport: getIcon('ic_collection_report'),
  pastDueAccounts: getIcon('ic_past_due_report'),
  loansReceivable: getIcon('ic_loans_receivable_monitoring'),
  loanInterest: getIcon('ic_loan_interest_monitoring'),
  claimedChangeMonitoring: getIcon('ic_claimed_change_monitoring'),
  apirAndAcopSchedule: getIcon('ic_apir_acop_sched'),
  closedAndDroppedOuts: getIcon('ic_closed_and_dropped_outs'),
  dailySummaryReport: getIcon('ic_daily_summary_report'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      {
        title: 'global configuration',
        path: PATH_DASHBOARD.general.configuration,
        icon: ICONS.generalConfiguration,
        roles: ['READ_GLOBAL_CONFIG'],
      },
      {
        title: 'branch configuration',
        path: PATH_DASHBOARD.general.branchConfiguration,
        icon: ICONS.generalBranchConfiguration,
        roles: ['READ_BRANCH_CONFIG'],
      },
      {
        title: 'SMS configuration',
        path: PATH_DASHBOARD.general.smsConfiguration,
        icon: ICONS.generalSMSConfiguration,
        roles: ['READ_SMS_CONFIG'],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',

    items: [
      // BRANCH
      { title: 'branch', path: PATH_DASHBOARD.branch.list, icon: ICONS.branch, roles: ['READ_BRANCH'] },

      // ROLE
      { title: 'role', path: PATH_DASHBOARD.role.list, icon: ICONS.role, roles: ['READ_ROLE'] },

      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: ['READ_INTERNAL_ACCOUNT', 'ROOT_ACCOUNT_ACCESS'],
      },

      // AGENT
      {
        title: 'agent',
        path: PATH_DASHBOARD.agent.root,
        icon: ICONS.agent,
        roles: ['READ_AGENT'],
      },
    ],
  },

  // LOAN-ADMINISTRATION

  {
    subheader: 'loan administration',
    items: [
      { title: 'loan', path: PATH_DASHBOARD.loan.list, icon: ICONS.loan, roles: ['READ_LOAN'] },

      {
        title: 'borrower',
        path: PATH_DASHBOARD.borrower.list,
        icon: ICONS.borrower,
        roles: ['READ_EXTERNAL_ACCOUNT'],
      },
      {
        title: 'payment collection',
        path: PATH_DASHBOARD.collection.root,
        icon: ICONS.collection,
        children: [
          { title: 'ATM-loan', path: PATH_DASHBOARD.collection.atmList },
          { title: 'Other-loan', path: PATH_DASHBOARD.collection.otherList },
          { title: 'Clusters Collection List', path: PATH_DASHBOARD.collection.clustersCollectionList },
          { title: 'Other Loans Collection List', path: PATH_DASHBOARD.collection.otherCollectionList },
          { title: 'Gcash', path: PATH_DASHBOARD.collection.gcashCollectionList },
        ],
        roles: ['COLLECT_PAYMENT'],
      },
    ],
  },

  // REPORTS

  {
    subheader: 'reports',
    items: [
      {
        title: 'Loan Releases',
        path: PATH_DASHBOARD.reports.loanReleases,
        icon: ICONS.loanReleases,
        roles: ['READ_LOAN_RELEASES'],
      },
      {
        title: 'Collection',
        path: PATH_DASHBOARD.reports.collection,
        icon: ICONS.collectionReport,
        roles: ['READ_COLLECTION'],
      },
      {
        title: 'Past Due Accounts',
        path: PATH_DASHBOARD.reports.pastDueAccounts,
        icon: ICONS.pastDueAccounts,
        roles: ['READ_PAST_DUE_ACCOUNTS'],
      },
      {
        title: 'Fully Paid Past Due Accounts',
        path: PATH_DASHBOARD.reports.fullyPaidPastDueAccounts,
        icon: ICONS.pastDueAccounts,
        roles: ['READ_PAST_DUE_ACCOUNTS'],
      },
      {
        title: 'Loans Receivable Monitoring',
        path: PATH_DASHBOARD.reports.loansReceivable,
        icon: ICONS.loansReceivable,
        roles: ['READ_LOANS_RECEIVABLE_MONITORING'],
      },
      {
        title: 'Loan Interest Monitoring',
        path: PATH_DASHBOARD.reports.loanInterest,
        icon: ICONS.loanInterest,
        roles: ['READ_LOAN_INTEREST_MONITORING'],
      },
      {
        title: 'Change Monitoring',
        path: PATH_DASHBOARD.reports.claimedChangeMonitoring,
        icon: ICONS.claimedChangeMonitoring,
        children: [
          { title: 'Claimed Change', path: PATH_DASHBOARD.reports.claimedChangeMonitoring },
          { title: 'Unclaimed Change', path: PATH_DASHBOARD.reports.unclaimedChangeMonitoring },
        ],
        roles: ['COLLECT_PAYMENT'],
      },
      {
        title: 'ATM Daily Collection',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.atmDailyCollection,
        roles: ['READ_ATM_DAILY_COLLECTION'],
      },
      {
        title: 'APIR & ACOP Schedule',
        path: PATH_DASHBOARD.reports.apirAndAcopSchedule,
        icon: ICONS.apirAndAcopSchedule,
        roles: ['READ_ACOPAPIR_SCHEDULES'],
      },
      {
        title: 'Closed & Dropped Outs',
        path: PATH_DASHBOARD.reports.closedAndDroppedOuts,
        icon: ICONS.closedAndDroppedOuts,
        roles: ['READ_CLOSED_AND_DROPPED_OUTS'],
      },
      {
        title: 'Summary Report',
        path: PATH_DASHBOARD.reports.dailySummaryReport,
        icon: ICONS.dailySummaryReport,
        children: [
          { title: 'Daily Summary', path: PATH_DASHBOARD.reports.dailySummaryReport },
          { title: 'Monthly Summary', path: PATH_DASHBOARD.reports.monthlySummaryReport },
        ],
        roles: ['READ_DAILY_SUMMARY_REPORT'],
      },
    ],
  },
];

export default navConfig;
