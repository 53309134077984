// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';

// sections
import { LoginForm } from '../../sections/auth/login';

import loginImage from '../../assets/illustrations/login.png';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 960,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle>
        <ContentStyle>
          <Card
            sx={{
              p: 5,
              m: 2,
              position: 'fixed',
              left: { xs: 5, md: '10%' },
              top: { xs: 10, md: 'auto' },
              zIndex: 50,
            }}
          >
            <Logo />
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to DMAY Loan Management
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>
            <LoginForm />
          </Card>
        </ContentStyle>
        <Box sx={{ width: { md: 800, xs: 400 }, height: 'auto', position: 'absolute', right: 0, bottom: 0 }}>
          <Image visibleByDefault disabledEffect alt="login" src={loginImage} />
        </Box>
      </RootStyle>
    </Page>
  );
}
